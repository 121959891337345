<template>
  <main id="nav" class="relative h-screen">
    <header class="h-24 sm:h-32 flex items-center z-30 w-full">
        <div class="container mx-auto px-6 flex items-center justify-between">
            <div class="uppercase font-black text-3xl">
                AGENDA.BANDA
            </div>
            <div class="flex items-center">
                <nav class="font-sen uppercase text-lg lg:flex items-center hidden">
                    <router-link to="/" class="py-2 px-6 flex text-black">INÍCIO</router-link>
                    <router-link to="/map" class="py-2 px-6 flex text-black">VER MAPA</router-link>
                </nav>
                <button class="lg:hidden flex flex-col ml-4">
                    <span class="w-6 h-1 mb-1">
                    </span>
                    <span class="w-6 h-1 mb-1">
                    </span>
                    <span class="w-6 h-1 mb-1">
                    </span>
                </button>
            </div>
        </div>
    </header>
    <router-view/>
    <footer class="text-center lg:text-left">
        <div class="text-center p-6">
            <span>© 2022 copyright </span>
            <a class="text-gray-600 font-semibold" href="https://www.agendabanda.com.br">agenda.banda</a>
        </div>
    </footer>
  </main>
</template>
