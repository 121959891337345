<template>
    <div class="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div class="container mx-auto px-6 flex relative py-16">
            <div class="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20">
                <span class="w-20 h-2 bg-gray-800 dark:bg-white mb-12">
                </span>
                <h1 class="font-bebas-neue uppercase text-6xl sm:text-8xl font-black flex flex-col leading-none dark:text-white text-gray-800">
                    Horário
                    <span class="text-5xl sm:text-7xl">
                        e Lugar Certo
                    </span>
                </h1>
                <p class="text-sm sm:text-base text-gray-700 dark:text-white mt-2">
                    {{msg}}
                </p>
                <div class="flex mt-8">
                    <a href="#" class="uppercase py-2 px-4 rounded-lg bg-black border-2 border-black text-white text-md mr-4 hover:bg-white hover:text-black">
                        AGENDAR AGORA
                    </a>
                    <a href="#" class="uppercase py-2 px-4 rounded-lg bg-black border-2 border-black text-white text-md hover:bg-white hover:text-black">
                        MINHA BANDA
                    </a>
                </div>
            </div>
            <div class="hidden sm:block sm:w-1/3 lg:w-3/5 relative text-right">
                <img src="/img/store/estudio_musical.jpg" class="max-w-xs md:max-w-sm m-auto rounded-lg"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
