<template>
  <Banner msg="Encontre estúdios próximos, agende horários que todos possam ir, sem perder o ritmo."/>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue'

export default {
  name: 'Home',
  components: {
    Banner
  }
}
</script>
